html {
  color: black;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  background: white;
  /* background-repeat: no-repeat;
  background-size: cover; */
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  width: 100%;
}

h1 {
  font-family: Tahoma;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.055em;
  text-transform: lowercase;
  color: #0d4215;

  @media screen and (min-width: 768px) {
    font-size: 24px;
    line-height: 33px;
  }
}

h2 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: -0.055em;
  text-transform: lowercase;
  color: #0d4215;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
}

h3 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.055em;
  text-transform: lowercase;
  color: #0d4215;

  @media screen and (min-width: 768px) {
    font-size: 26px;
    line-height: 32px;
  }
}

h4 {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.055em;
  text-transform: lowercase;
  color: #0d4215;

  @media screen and (min-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
}

p {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #0d4215;

  @media screen and (min-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
}

li {
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 2.1vw;
  line-height: 2.3vw;

  text-align: center;

  color: #0d4215;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.success-msg {
  color: black;
}

.err-msg {
  color: red;
}
